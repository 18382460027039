<template>
  <div id="sidebar">
    <!--  <div class="receipt" id="topreceipt">
      <div id="header">FAST FOOD INDEX</div>
      <div id="motto">
        See cost of living in the United States, solely based on fast food
        costs.
      </div>
    </div> -->
    <div id="header">
      <img
        src="@/assets/header.png"
        v-lazy="'@/assets/header.png'"
        style="opacity: 0; transition: opacity 600ms"
        v-on:load="(event) => (event.target.style.opacity = 1)"
      />
    </div>
    <div id="descriptContainer">
      <div id="descript">
        Did you know that fast food prices vary across the country?
        <span style="font-weight: 400"
          >Sometimes, they vary a lot! These differences are an informal
          indicator of an area's cost of living.</span
        >
      </div>
    </div>
    <div id="storemob">
      <div id="stores">
        <div v-for="(data, key) in this.receiptData" :key="key">
          <StoreButton
            :img="'/logos/' + key + '.png'"
            :store="data.name"
            :product="data.product"
            :color="data.color"
            :active="this.store === key"
            @click="setStore(key)"
          />
        </div>
      </div>
    </div>
    <div id="traycontainer">
      <div id="tray">
        <div class="food">
          <img :src="'/food/' + this.store + '.png'" />
        </div>
      </div>
      <div class="receipt" id="billreceipt" ref="receipt">
        <div style="text-align: center">
          <b>{{ this.receiptData[this.store].header }}</b>
          <br />
          {{ this.receiptData[this.store].subheader }}
        </div>
        <br /><br />
        <div
          v-if="this.local?.stores?.[this.store]?.length"
          style="color: darkmagenta"
        >
          <b style="display: block; margin-bottom: -11px"
            ><i>Stores near {{ this.local.city }}:</i></b
          >

          <br />
          <div v-for="price in this.local.stores[this.store]" :key="price.name">
            <span
              style="cursor: pointer"
              @click="
                this.map.flyTo({
                  center: [price.longitude, price.latitude],
                  zoom: 13,
                })
              "
              >{{ price.name.substring(0, 29) }}</span
            >
            <span style="float: right"
              >${{ Number(price.price).toFixed(2) }}</span
            >
            <br />
          </div>
          <div
            style="
              width: 100%;
              margin-top: 2px;
              padding-top: 11px;
              margin-bottom: 3px;
            "
          >
            <!-- invertPercentile: function(percentile, chain, location){ -->

            {{
              this.invertPercentile(
                this.local.percentile[this.store],
                this.store,
                this.local.city.split(",")[0]
              )
            }}
          </div>

          <br />
        </div>
        <div
          v-else-if="
            this.local &&
            !this.local.error &&
            !this.local?.stores?.[this.store]?.length
          "
          style="color: darkmagenta"
        >
          <b
            ><i>No locations near {{ this.local.city }}</i></b
          ><br /><br />
        </div>

        <i style="display: block">Least expensive:</i><br />
        <div
          v-for="price in this.receiptData[this.store].cheap"
          :key="price[0]"
        >
          <span
            style="cursor: pointer"
            @click="this.map.flyTo({ center: [price[3], price[2]], zoom: 13 })"
            >{{ price[0] }}</span
          ><span style="float: right">{{ price[1] }}</span> <br />
        </div>

        <br />
        <i>Average:</i
        ><span style="float: right">{{
          this.receiptData[this.store].average
        }}</span
        ><br /><br />
        <i style="display: block">Most expensive:</i><br />
        <div
          v-for="price in this.receiptData[this.store].expensive"
          :key="price[0]"
        >
          <span
            style="cursor: pointer"
            @click="this.map.flyTo({ center: [price[3], price[2]], zoom: 13 })"
            >{{ price[0] }}</span
          ><span style="float: right">{{ price[1] }}</span> <br />
        </div>
        <br /><br />
        <div id="chartContainer">
          <ChartBar
            v-for="bar in this.receiptData[this.store].chart"
            :key="bar.x"
            :y="bar.y"
            :maxY="this.receiptData[this.store].chartMaxY"
          />
        </div>
        <div id="labelContainer">
          <div
            class="label"
            v-for="bar in this.receiptData[this.store]?.chart?.filter(
              (bar, index) =>
                index === 0 ||
                index === this.receiptData[this.store].chart.length - 1 ||
                index %
                  Math.floor(this.receiptData[this.store].chart.length / 7) ===
                  0
            )"
            :key="bar.x"
          >
            ${{ bar.x }}
          </div>
        </div>
        <br /><br />
      </div>
    </div>
    <div id="zipCodeBox">
      <div id="zipExplain">Enter your zip code to see your prices</div>
      <div id="inputBox">
        <input
          ref="input"
          id="zipCode"
          pattern="\d*"
          type="number"
          placeholder="Zip code"
          @keyup.enter="getZipData"
          :value="this.zip"
          @input="(event) => (this.zip = event.target.value)"
        />

        <div
          v-if="this.zip.length == 5 && !this.loading"
          @click="getZipData()"
          class="zipCodeButton"
        >
          ->
        </div>
        <div v-if="this.loading" class="zipCodeButton loading">-></div>
      </div>
    </div>
    <div class="aboutContainer" id="aboutdesktop">
      <div class="about">
        I found this data by reverse-engineering each chain's mobile app, and
        pretending to place an order at each location. The raw data in CSV
        format is available to
        <a
          style="font-style: normal; font-weight: 600; color: black"
          href="/data.zip"
          >download here</a
        >. <br /><br />
        <p style="font-size: 1rem">
          By
          <a
            style="font-style: normal; font-weight: 600; color: black"
            href="https://walzr.com"
            >Riley Walz</a
          >. Not affilated with any restaurant chains. Data updated
          {{
            new Date(
              this.receiptData[this.store].updated_at
            ).toLocaleDateString("en-US", { month: "long", year: "numeric" })
          }}.
        </p>
      </div>
    </div>
  </div>
  <div id="mapContainer">
    <div id="map"></div>
    <div id="storeInfo" v-if="showInfo">
      <div style="font-size: 1.2rem; font-weight: bold">
        ${{ storeInfo.latest_price.toFixed(2) }}
      </div>
      <div style="font-size: 0.75rem; color: grey; margin-bottom: 5px">
        checked {{ storeInfo.latest_price_checked }}
      </div>
      <div style="font-size: 0.9rem">{{ storeInfo.address }}</div>
      <div style="font-size: 0.9rem">
        {{ storeInfo.town }}, {{ storeInfo.state }}
      </div>
    </div>
    <!-- <div id="keyContainer">
      <div id="scale" :style="this.receiptData[this.store].gradient"></div>
      <div id="key">
        <KeyCircle
          v-for="dot in this.receiptData[this.store].key"
          :key="dot.width"
          :width="dot.width"
          :color="dot.color"
          :price="dot.price"
        />
      </div>
    </div> -->
  </div>
  <div class="aboutContainer" id="aboutmobile">
    <div class="about">
      I found this data by reverse-engineering each chain's mobile app, and
      pretending to place an order at each location. The raw data in CSV format
      is available to
      <a
        style="font-style: normal; font-weight: 600; color: black"
        href="/data.zip"
        >download here</a
      >. <br /><br />
      <p style="font-size: 1rem">
        By
        <a
          style="font-style: normal; font-weight: 600; color: black"
          href="https://walzr.com"
          >Riley Walz</a
        >. Not affilated with any restaurant chains. Data updated
        {{
          new Date(this.receiptData[this.store].updated_at).toLocaleDateString(
            "en-US",
            { month: "long", year: "numeric" }
          )
        }}.
      </p>
    </div>
  </div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import StoreButton from "./StoreButton.vue";
import ChartBar from "./ChartBar.vue";

export default {
  name: "App",
  components: {
    StoreButton,
    ChartBar,
  },
  async mounted() {
    this.map = new maplibregl.Map({
      container: "map",
      cooperativeGestures: this.$isMobile() ? true : false,
      bounds: [-124.68721, 24.4319770250494, -66.96466, 49.38905],
      style:
        "https://api.protomaps.com/styles/v2/white.json?key=1466e5440ed3e322",
    });

    // Add layer after the map is loaded
    this.map.on("load", () => {
      this.addLayersToMap(this.store);

      // Add visibility control for zoom level
      this.map.on(
        "zoom",
        function () {
          if (this.map.getZoom() > 11.5) {
            this.map.setLayoutProperty("priceLabels", "visibility", "visible");
          } else {
            this.map.setLayoutProperty("priceLabels", "visibility", "none");
          }
        }.bind(this)
      );
    });
  },
  watch: {
    store(newStore) {
      this.addLayersToMap(newStore);
    },
  },
  data() {
    return {
      zip: "", //zip code
      loading: false,
      local: null,
      store: "subway", // 1 mcd, 2 chick, 3 bell, 4 chipotle
      receiptData: window.manifest,
      storeInfo: null,
      showInfo: false,
    };
  },
  methods: {
    invertPercentile: function (percentile, chain, location) {
      if (percentile >= 50) {
        return `Buying ${
          this.receiptData[chain].noun
        } in ${location} is more expensive than ${percentile.toFixed(
          0
        )}% of the country.`;
      } else {
        return `Buying ${
          this.receiptData[chain].noun
        } in ${location} is cheaper than ${(100 - percentile).toFixed(
          0
        )}% of the country.`;
      }
    },
    getZipData: function () {
      this.loading = true;
      let that = this;
      fetch("/zip/" + this.zip)
        .then((x) => x.json())
        .then((data) => {
          that.loading = false;

          if (data.error) return alert(data.error);
          that.local = data;
          that.$refs.input.blur();
          that.$refs["receipt"].scrollIntoView({ behavior: "smooth" });
          that.map.flyTo({
            center: [that.local.longitude, that.local.latitude],
            zoom: 10,
          });

          console.log(that.local);
        });
    },
    setStore: function (storeSlug) {
      this.store = storeSlug;
    },
    addLayersToMap(store) {
      if (this.map.getSource("storeLayer")) {
        this.map.removeLayer("storeLayer");
        this.map.removeLayer("priceLabels");
        this.map.removeSource("storeLayer");
      } else {
        // Add mouseenter event to the storeLayer
        this.map.on("mouseenter", "storeLayer", (e) => {
          this.map.getCanvas().style.cursor = "pointer";
          // Get the store_id from the properties of the feature
          const storeId = e.features[0].properties.i;

          // Fetch data from the server
          fetch(`/store/${this.store}/${storeId}`)
            .then((response) => response.json())
            .then((data) => {
              // Store the data
              this.storeInfo = data;
              this.showInfo = true;
            });
        });

        // Add mouseleave event to the storeLayer
        this.map.on("mouseleave", "storeLayer", () => {
          this.map.getCanvas().style.cursor = "";
          this.showInfo = false;
        });
      }

      this.map.addSource("storeLayer", {
        type: "vector",
        tiles: [`https://fastfoodindex.walzr.com/map/${store}/{z}/{x}/{y}.mvt`],
      });

      this.map.addLayer({
        id: "storeLayer",
        type: "circle",
        source: "storeLayer",
        "source-layer": "gas_prices", // This should be the name of your layer in the MVT data
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            3,
            2,
            5,
            4,
            12,
            8,
          ],
          "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "a"],
            0,
            "#44ce1b",
            0.2,
            "#bbdb44",
            0.4,
            "#f7e379",
            0.6,
            "#f2a134",
            0.8,
            "#e51f1f",
            1,
            "#e51f1f",
          ],
          "circle-stroke-width": 1,
          "circle-stroke-color": "#ffffff",
        },
      });

      this.map.addLayer({
        id: "priceLabels",
        type: "symbol",
        source: "storeLayer",
        "source-layer": "gas_prices",
        layout: {
          "text-field": [
            "number-format",
            ["get", "b"],
            { locale: "en-US", currency: "USD" },
          ],
          "text-font": ["Noto Sans Regular"],
          "text-size": 10,
          "text-offset": [0, 0.75],
          "text-anchor": "top",
          "text-allow-overlap": true,
          visibility: "none",
        },
        paint: {
          "text-color": "#000000",
        },
      });
    },
  },
};
</script>

<style>
@media only screen and (max-width: 400px) {
  #header img {
    max-width: 308px !important;
    max-height: 190.91px !important;
  }

  #header {
    height: 190px !important;
  }
  html {
    font-size: 13px !important;
  }
  .button {
    height: 38px !important;
  }

  #stores > * + * {
    margin-left: 10px !important;
  }

  #storemob {
    width: 100vw;
    overflow-x: scroll;
  }

  #stores {
    flex-wrap: nowrap !important;

    width: fit-content !important;
  }

  #billreceipt {
    width: 245px !important;
    margin-top: -350px !important;
    min-width: auto !important;
  }

  #tray {
    align-items: baseline !important;
  }
  .food {
    width: 145px !important;
    margin-right: 80px !important;
    margin-top: 56px !important;
  }

  #tray {
    height: auto !important;
    width: 100% !important;
    max-width: none !important;
  }

  .food img {
    width: 90vw !important;
    margin-bottom: 50px !important;
  }
}

#aboutmobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  #app {
    flex-direction: column;
  }

  #aboutmobile {
    display: block !important;
  }

  #aboutdesktop {
    display: none !important;
  }

  .food img {
    width: 100%;
    margin-bottom: 106px;
  }

  #sidebar {
    height: auto !important;
  }

  #billreceipt {
    margin-top: -427px;
    margin-left: 52px !important;
  }

  #sidebar {
    width: 100% !important;
    overflow-y: initial !important;
  }

  #mapContainer {
    width: 100% !important;
  }

  #map {
    height: 90vh !important;
  }

  #traycontainer {
    flex-direction: column;
    margin-top: 10px !important;
    align-items: center !important;
  }

  #tray {
    background-position: top !important;
    margin-top: 19px !important;
    margin-left: -195px !important;
    transform: scale(1.5);
    transform-origin: top;
  }

  body,
  html {
    overflow-y: initial !important;
  }
}

#zipCodeBox {
  display: flex;
  justify-content: space-around;
  max-width: 500px;
  padding: 0 15px;
  margin: 30px auto;
  margin-top: 48px;
}

#zipCodeBox > * + * {
  margin-left: 4px;
}

#inputBox {
  /*width: 100px;*/
}

#zipCode {
  border: none;
  font-family: Merchant, Courier;
  font-size: 3rem;
  width: 9rem;
  background-image: url(assets/paper.jpg);
  background-position: center;
  filter: brightness(110%);
  padding: 10px;
  border-radius: 4px;
}

#inputBox {
  position: relative;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.loading {
  animation: blinker 1s linear infinite;
}

.zipCodeButton {
  position: absolute;
  right: 5px;
  font-family: Merchant, Courier;
  border-radius: 500px;
  height: 2rem;
  background-color: #87ceeb;
  line-height: 2rem;
  font-size: 1.8rem;
  top: calc(50% - 1rem);
  width: 2rem;
  cursor: pointer;
  text-align: center;
}

#zipExplain {
  font-family: "Public Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  max-width: 282px;
  color: #333;
  text-transform: uppercase;
}

#mapContainer {
  position: relative;
  width: 50%;
  height: 100%;
}

#scale {
  width: 100%;
  height: 25px;
  border-radius: 3px;
}

#keyContainer {
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 8px;
  right: 0;
  margin: auto;
  width: fit-content;
  background: #ffffff90;
  padding: 8px;
}

#key {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

#key > * + * {
  margin-left: 18px;
}

#chartContainer {
  height: 75px;
  display: flex;
  align-items: flex-end;
  border-bottom: 0.5px solid black;
}
#labelContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.label {
  flex: 1 1 0px;
  font-size: 1.3rem;
  width: 1px;
  transform: rotate(45deg) translateY(12px) translateX(12px);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.0001em !important;
}

#stores {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
  box-sizing: border-box;
}

#stores > * + * {
  margin-left: 15px;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

#sidebar {
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  background-color: #fabc2a;
  background-image: url("assets/white-wall.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.receipt {
  background-image: url(assets/paper.jpg);
  background-position: center;
  filter: brightness(110%);
  background-color: #fff;
  padding: 20px;
  text-transform: uppercase;
  font-family: merchant, Courier;
  /*height: 250px;*/
  font-size: 1.6rem;
}

#topreceipt {
  width: 340px;
  transform: rotate(-4deg) translateY(-130px);
  max-height: 400px;
  padding-top: 200px;
}

#billreceipt {
  width: 340px;
  min-width: 340px;
  margin-left: -50%;
  margin-right: 39px;
  transform: rotate(3deg);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding-bottom: 47px;
}

#descriptContainer {
  background-color: #fff;
  margin: -10px 0 30px 0;
}

#descript {
  max-width: 500px;
  padding: 30px 10px;
  font-weight: 600;
  margin: 0 auto;
  color: #333;
  font-size: 1.2rem;
  font-family: "Public Sans", sans-serif;
}

.aboutContainer {
  background-color: #fff;
  margin: 0px 0 0px 0;
}

.about {
  max-width: 500px;
  padding: 30px 10px;
  margin: 0 auto;
  color: #333;
  font-size: 1.2rem;
  font-family: "Public Sans", sans-serif;
}

#motto {
  font-size: 1.9rem;
  padding-bottom: 10px;
  /*border-bottom: 2px dashed black;*/
}

#header {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 80);
  background-image: url(assets/sky.jpg);
  height: 248px;
}

#header img {
  max-width: 400px;
  max-height: 248px;
  margin-bottom: -10px;
  margin-top: 25px;
}

#traycontainer {
  width: 100%;
  position: relative;
  justify-content: space-between;
  display: flex;
  margin-top: 40px;
}

#tray {
  background-image: url(assets/tray.png);
  height: 500px;
  max-width: 500px;
  width: calc(100% - 50px);
  background-position: center;
  /*padding: 0 30px;*/
  margin-left: 50px;

  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food {
  max-width: 300px;
  margin-right: 80px;
  /*margin-top: 60px;*/
  /* transform: rotate(-40deg); */
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
}

.food img {
  width: 100%;
}

#storeInfo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 150px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-family: "PUBLIC SANS", sans-serif;
}
</style>
