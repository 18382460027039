<template>
  <div class="bar" :style="cssVars"></div>
</template>

<script>
export default {
  name: "ChartBar",
  props: {
    y: Number,
    maxY: Number,
  },
  data() {
    return {
      bgPosX: Math.floor(Math.random() * 101),
      bgPosY: Math.floor(Math.random() * 101),
    };
  },
  computed: {
    cssVars() {
      return {
        "--barHeight": (this.y / this.maxY) * 100 + "%",
        "background-position": `${this.bgPosX}% ${this.bgPosY}%`,
      };
    },
  },
};
</script>

<style>
.bar {
  flex: 1 1 0px;
  height: var(--barHeight);
  background-color: #000000;
  background-image: url("assets/natural-paper.png");
  background-position: var(--background-position);
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
</style>
