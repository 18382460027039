<template>
  <div :class="{ active, button: true }" :style="cssVars">
    <img :src="this.img" />
    <div class="names">
      <div class="store">{{ this.store }}</div>
      <div class="product">{{ this.product }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreButton",
  props: {
    img: String,
    store: String,
    product: String,
    color: String,
    active: Boolean,
  },
  computed: {
    cssVars() {
      return {
        "--border-color": this.color,
        transform: `rotate(${Math.random() * 4 - 2}deg)`,
      };
    },
  },
};
</script>

<style>
.button > * + * {
  margin-left: 5px;
}

.button {
  display: flex;
  margin-bottom: 10px;
  transition: 0.3s all;
  height: 50px;
  justify-content: center;

  width: auto;
  background: white;
  padding-right: 10px;
  border-radius: 7px;
  font-family: "Public Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  cursor: pointer;
  margin: 4px 0;
  opacity: 0.7;
  filter: grayscale(100);
}

.button img:not(.active img) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.active {
  /*transform: scale(2);*/
  /*background: black;*/
  opacity: 1;
  filter: grayscale(0);
  font-weight: 600;
  box-shadow: 0 0 0 4px var(--border-color);
}
.button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  box-shadow: 0 0 0 4px var(--border-color);
}
.names {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.store {
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #00000090;
  overflow: hidden;
  white-space: nowrap;
}
.product {
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
}

.button img {
  height: 50px;
  width: 50px;
}

@media only screen and (max-width: 400px) {
  .button img {
    height: 38px !important;
    width: 38px !important;
  }
}
</style>
